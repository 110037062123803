import React from "react";
import { graphql } from "gatsby";
import PostLayout from "../components/postLayout";
import SEO from "../components/seo";
import { Heading, Text, Flex } from "@chakra-ui/core";
import PropTypes from "prop-types";
import { url } from "../../const";

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  const slug = post.fields.slug;
  const name = slug.slice(7, slug.length - 1);
  return (
    <PostLayout>
      <SEO
        title={post.frontmatter.title}
        description={post.excerpt}
        authorName={post.frontmatter.author}
        image={`${url}/images/posts/${name}.jpeg`}
        url={`${url}${slug}`}
      />
      <div style={{ marginTop: "2rem" }}>
        <Flex flexDirection="column" alignItems="center">
          <Heading textAlign="center" as="h1" size="xl">
            {post.frontmatter.title}
          </Heading>
          <Text mt={"2"} textAlign="center">
            Written by {post.frontmatter.author}
          </Text>
          <p>{post.frontmatter.date}</p>
          <img
            style={{ margin: "2rem 0" }}
            src={`/images/posts/${name}.jpeg`}
            width={"80%"}
            alt={name}
          />
        </Flex>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </PostLayout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        author
        date(formatString: "Do MMMM, YYYY")
      }
      excerpt
    }
  }
`;

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
};
