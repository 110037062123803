import React from "react";
import { Flex, Text } from "@chakra-ui/core";
import logo from "../../static/images/other/logo.png";
import { Link } from "gatsby";
import Footer from "./footer";
import PropTypes from "prop-types";

export default function PostLayout({ children }) {
  return (
    <Flex
      flexDirection="column"
      width={[
        "80vw",
        "42rem",
        // "64rem"
      ]}
      margin={["2rem auto"]}
    >
      <Flex justifyContent="center" alignItems="center">
        <img width="35" height="35" src={logo} alt="Logo" />
        <Text
          ml={2}
          fontFamily={"Playfair Display, serif"}
          fontWeight={500}
          fontSize={[24]}
          as="h2"
        >
          <Link to="/">The Aawaaz Blog</Link>
        </Text>
      </Flex>
      <Flex>{children}</Flex>
      <Footer post={true} />
    </Flex>
  );
}

PostLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
